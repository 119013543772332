import React, { Component } from "react";

//import Button from 'react-bootstrap/Button';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
//import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';
//import Carousel from 'react-bootstrap/Carousel';

import gallery34 from '../../images/gallery/gallery34.jpg'
import gallery1 from '../../images/gallery/gallery1.jpg'
import gallery3 from '../../images/gallery/gallery3.jpg'
import gallery4 from '../../images/gallery/gallery4.jpg'
import gallery7 from '../../images/gallery/gallery7.jpg'
import gallery9 from '../../images/gallery/gallery9.jpg'
import gallery12 from '../../images/gallery/gallery12.jpg'
import gallery17 from '../../images/gallery/gallery17.jpg'
import gallery21 from '../../images/gallery/gallery21.jpg'
import gallery35 from '../../images/gallery/gallery35.jpg'
import gallery36 from '../../images/gallery/gallery36.jpg'
import coldplunge from '../../images/gallery/coldplunge.jpg'

class Studio extends Component {
    constructor(props) {
        super(props);

        this.state = {
            gallery: [
                {
                    id: 1,
                    src: gallery34,
                    crop: 'cover'
                },
                {
                    id: 2, 
                    src: gallery35,
                    crop: 'cover'
                },
                {
                    id: 3,
                    src: gallery4,
                    crop: 'cover'
                },
                {
                    id: 4,
                    src: gallery3,
                    crop: 'cover'
                },
                {
                    id: 5,
                    src: gallery1,
                    crop: 'scale-down'
                },
                {
                    id: 6,
                    src: gallery9,
                    crop: 'scale-down'
                },
                {
                    id: 7,
                    src: gallery7,
                    crop: 'scale-down'
                },
                {
                    id: 8,
                    src: gallery12,
                    crop: 'scale-down'
                },
                {
                    id: 9,
                    src: gallery17,
                    crop: 'cover'
                },
                {
                    id: 10,
                    src: gallery36,
                    crop: 'cover'
                },
                {
                    id: 11,
                    src: gallery21,
                    crop: 'cover'
                },
                {
                    id: 12,
                    src: coldplunge,
                    crop: 'cover'
                }
            ]
        };
    }

    componentDidMount() {
        //this.props.history.push("/");
    }

    render() {
        const { gallery } = this.state;

        return (
            <div className="h-100">
                <Navbar expand="lg" variant={this.state.variant} bg={this.state.variant}>
                    <Container>
                        <Navbar.Brand style={{ margin: '20px', fontSize: '150%' }}>Studio</Navbar.Brand>
                    </Container>
                </Navbar>

                <Container className="py-3">
                    {/* <div>Class Schedule</div> */}
                    <Row xs={1} md={2} className="g-4">
                        {gallery.map((photo, index) => (
                            <Image key={index} fluid rounded src={photo.src} alt="Art Fiedler" className="img-thumbnail"
                            style={{
                                minHeight: '428.5px', minWidth: '342.5px', display: 'block', border: '0px', objectFit: photo.crop
                            }}></Image>
                        ))}
                    </Row>
                </Container>
            </div>
        );
    }
}

export default Studio;