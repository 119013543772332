import React, { Component } from "react";

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';

import mainLogo from '../../images/aoy.png';

class AdminNavBar extends Component {

    render() {
        return (
            <div>
                
                <Navbar collapseOnSelect expand="lg" bg="light" variant="light" >
                    <Container >
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="m-auto">
                                <Nav.Link href="/admin/dashboard">Home</Nav.Link>
                                <Nav.Link href="/admin/classes">Classes</Nav.Link>
                                <Nav.Link href="/admin/instructors">Instructors</Nav.Link>
                            </Nav>


                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>
        );
    }
}


export default AdminNavBar;