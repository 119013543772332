import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
//import Card from 'react-bootstrap/Card';



class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    componentDidMount() {
       this.props.history.push("/admin/dashboard");
    }

    render() {

        return (
            <div>
                Welcome to admin dashboard
            </div>
        );
    }
}

Dashboard.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps
)(withRouter(Dashboard));