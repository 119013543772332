import React, { Component } from "react";

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';

import mainLogo from '../../images/aoy.png';

class TopNavBar extends Component {

    render() {
        return (
            <div>
                <a href="/">
                    <Image
                        fluid
                        rounded
                        src={mainLogo}
                        alt="..."
                        className="img-thumbnail"
                        style={{
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            maxHeight: '221.5px',
                            maxWidth: '725.5px',
                            width: '50%',
                            display: 'block',
                            border: '0px'
                        }}>
                    </Image>
                </a>
                <Navbar collapseOnSelect expand="lg" bg="light" variant="light" >
                    <Container >
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="m-auto">
                                <Nav.Link href="/">Home</Nav.Link>
                                <Nav.Link href="/studio">Studio</Nav.Link>
                                <Nav.Link href="/classes">Classes</Nav.Link>
                                {/* <Nav.Link href="/purchase">Purchase</Nav.Link> */}
                                <Nav.Link href="/instructors">Instructors</Nav.Link>
                            </Nav>


                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>
        );
    }
}


export default TopNavBar;