import axios from 'axios';

const restService = {
    
    classes: {
        getClasses() {
            return axios.get(`/api/classes/getClasses`);
        }
    },
    products: {
        getProducts () {
            return axios.get(`/api/products/getProducts`);
        },
        getProduct(id) {
            return axios.get(`/api/products/getProduct/${id}`);
        }
    }
}



export default restService;