import React, { Component } from "react";
import restService from '../../../utils/restService';

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';

class Classes extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showAdd: false,
            classes: [],
            yogaClass: {
                instructor: "",
                time: "",
                classType: "",
                location: ""
            }
        };
    }

    componentDidMount() {
        this.props.history.push("/admin/classes");
        this.getClasses();
    }

    //#region API
    getClasses = () => {
        restService.classes.getClasses().then(res => {
            const data = res.data;

            console.log("logging data: ", data);
            this.setState({ classes: data });
        }).catch(err => {
            console.log("Unexpected error while retrieving products.");
        });
    }

    //#endregion

    //#region EVENTS
    handleShowAdd = () => { this.setState({ showAdd: true }); }
    //#endregion

    //#region HANDLERS
    //#endregion

    //#region DISPLAY
    showClasses = (classes) => {

    }
    //#endregion

    render() {
        const { classes } = this.state;

        return (
            <div >

                <Card className="my-3 mx-3">
                    <Card.Header className="d-flex justify-content-between align-items-center">
                        Classes
                        <Form>
                            <Button className="mx-1" onClick={this.handleShowAdd}>Add</Button>
                        </Form>
                    </Card.Header>
                    <Card.Body>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Day</th>
                                    <th>Class</th>
                                    <th>Instructor</th>
                                    <th>Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.showClasses(classes)}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>

                <Modal show={this.state.showAdd} size="lg" onHide={this.handleCloseAdd} animation={true} backdrop="static" keyboard={false} centered>
                    <Modal.Header>
                        <Modal.Title>Add Class</Modal.Title>
                        <button type="button" onClick={this.handleCloseAdd} className="btn-close" aria-label="Close"></button>
                    </Modal.Header>

                    <Modal.Body className="py-2">
                        <Form noValidate>
                            <Form.Group className="py-3">
                                <Form.Label>Weekday</Form.Label>
                            </Form.Group>
                        </Form>

                    </Modal.Body>
                    <Modal.Footer>
                        <Form>
                            <Button type="submit" onClick={this.onSubmitAdd} className="mx-2">
                                Submit
                            </Button>
                            <Button onClick={this.handleCloseAdd} className="mx-2">
                                Cancel
                            </Button>
                        </Form>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default Classes;